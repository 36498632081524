<template>
  <div>
    <div class="d-inline-block mr-2 mt-3 mt-sm-0 mb-3 mb-sm-3">
      <a-input-search placeholder="Search by name..." @search="onSearch" />
    </div>
    <div class="mb-4">
      <a-table
        :columns="columns"
        :dataSource="filteredData"
        :pagination="true"
        :class="$style.table"
        rowKey="id"
        :loading="isLoading"
      >
        <template slot="name" slot-scope="name, record">
          <div>
            <div v-if="isAdmin">
              <router-link
                :to="{ path: '/farmasi/details/' + record.id }"
                class="kit__utils__link font-size-16"
                >{{ record.name }}</router-link
              >
            </div>
            <div v-if="!isAdmin">{{ record.name }}</div>
            <div class="text-gray-4">{{ record.city ? record.city : '-' }}</div>
          </div>
        </template>
        <template slot="createdAt" slot-scope="createdAt">
          <div>
            <div>
              {{ createdAt ? moment(createdAt).format('DD MMM YYYY') : '-' }}
            </div>
          </div>
        </template>
        <template slot="address" slot-scope="address">
          <div>
            <div>{{ address ? address : '-' }}</div>
          </div>
        </template>
        <template slot="city" slot-scope="city">
          <div>
            <div>{{ city ? city : '-' }}</div>
          </div>
        </template>
        <template slot="certificate" slot-scope="certificate">
          <div>
            <div>{{ certificate ? certificate : '-' }}</div>
          </div>
        </template>
        <template slot="message" slot-scope="message">
          <div>
            <div>{{ message ? message : '-' }}</div>
          </div>
        </template>
        <template slot="email" slot-scope="email">
          <div>
            <div>{{ email ? email : '-' }}</div>
          </div>
        </template>
        <template slot="isApproved" slot-scope="isApproved">
          <div>
            <div>
              {{
                isApproved == true
                  ? 'Disetujui'
                  : isApproved == false
                  ? 'Ditolak'
                  : 'Menunggu Verifikasi'
              }}
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import { getAll } from '@/services/axios/api/farmasi'
import fuzzysort from 'fuzzysort'
import moment from 'moment'
const columns = [
  {
    title: 'Nama Farmasi',
    dataIndex: 'name',
    className: 'bg-transparent text-gray-6 width-50',
    fixed: 'left',
    scopedSlots: { customRender: 'name' },
    sorter: (a, b) => {
      return a.name.localeCompare(b.name)
    },
    filters: [],
    onFilter: (value, record) => {
      return record.city === value
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'email' },
    sorter: (a, b) => {
      return a.email.localeCompare(b.email)
    },
  },
  {
    title: 'Tanggal Bergabung',
    dataIndex: 'createdAt',
    className: 'bg-transparent text-gray-6 width-50',
    scopedSlots: { customRender: 'createdAt' },
    sorter: (a, b) => {
      return a.createdAt.localeCompare(b.createdAt)
    },
  },
  {
    title: 'Alamat Farmasi',
    dataIndex: 'address',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'address' },
    sorter: (a, b) => {
      return a.address.localeCompare(b.address)
    },
  },
  // {
  //   title: 'Kota',
  //   dataIndex: 'city',
  //   className: 'bg-transparent text-gray-6',
  //   scopedSlots: { customRender: 'city' },
  //   sorter: (a, b) => { return a.city.localeCompare(b.city) },
  // },
  // {
  //   title: 'Sertifikat',
  //   dataIndex: 'certificate',
  //   className: 'bg-transparent text-gray-6',
  //   scopedSlots: { customRender: 'certificate' },
  //   sorter: (a, b) => { return a.certificate.localeCompare(b.certificate) },
  // },
  {
    title: 'Status',
    dataIndex: 'isApproved',
    className: 'bg-transparent text-gray-6',
    scopedSlots: { customRender: 'isApproved' },
    sorter: (a, b) => {
      return a.isApproved.localeCompare(b.isApproved)
    },
  },
  // {
  //   title: 'Alasan Ditolak',
  //   dataIndex: 'message',
  //   className: 'bg-transparent text-gray-6',
  //   scopedSlots: { customRender: 'message' },
  //   sorter: (a, b) => { return a.message.localeCompare(b.message) },
  // },
]

export default {
  name: 'TabelFarmasi',
  data: function () {
    return {
      columns,
      items: [],
      isLoading: true,
      filteredData: [],
      isAdmin: false,
    }
  },
  created() {
    this.getListData()
    this.getRole()
  },
  methods: {
    moment,
    onSearch(searchText) {
      if (searchText !== '') {
        const results = fuzzysort.go(searchText, this.items, { key: 'name' })
        const filteredData = results.map((result) => {
          return result.obj
        })
        console.log(results)
        this.filteredData = filteredData
      } else {
        this.filteredData = this.items
      }
    },
    getRole() {
      const role = window.localStorage.getItem('role')
      // console.log(role)
      if (role === 'admin') {
        this.isAdmin = true
      }
    },
    async getListData() {
      const cityFilters = []
      const cityFiltersComparison = []
      let res = await getAll()
      res = res.filter((el) => el.isApproved === true)
      console.log(res.length, 'farmasi')
      for (const i in res) {
        const user = res[i]
        if (user.city) {
          const comparison = user.city.toUpperCase()
          if (!cityFiltersComparison.includes(comparison)) {
            cityFilters.push(user.city)
            cityFiltersComparison.push(comparison)
          }
        }
      }
      cityFilters.forEach((el) => {
        columns[0].filters.push({
          text: el,
          value: el,
        })
      })
      this.columns = columns
      this.isLoading = false
      this.items = res
      this.filteredData = res
    },
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
